import { Component, computed, input, signal } from '@angular/core';
import {
    CompanyByIdDocument,
    Item,
    ListItemGQL,
    Location, LocationInput
} from '../../../../../../../../graphql/generated';
import { ModalService } from '../../../../../../core/services/modal.service';
import {
    CancelListingModalComponent
} from '../cancel-listing-modal/cancel-listing-modal.component';
import { ListItemModalComponent } from '../list-item-modal/list-item-modal.component';
import {
    ItemStatusBadgesComponent
} from '../../../../../components/item-status-badges/item-status-badges.component';
import { ImageDirective } from '../../../../../../core/directives/image.directive';
import { AuthService } from '../../../../../../auth/services/auth.service';
import { DateTimeService } from '../../../../../../core/services/date-time.service';
import { LocationService } from '../../../../../../core/services/location.service';
import { MessageService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { TooltipDirective } from '../../../../../../core/directives/tooltip.directive';
import { NgIf } from '@angular/common';

@Component({
    selector: 'company-manage-listing-card',
    standalone: true,
    imports: [
        ItemStatusBadgesComponent,
        ImageDirective,
        TooltipModule,
        NgIf
    ],
    templateUrl: './manage-listing-card.component.html'
})
export class ManageListingCardComponent {

    public readonly ANALYTICS_ENABLED = false;

    public item = input.required<Item>();
    public isLoading = signal<boolean>(false);

    canManage = computed(() => {
        return this.authService.isAuthenticated() && this.authService.hasSubscriptionAccess();
    });

    constructor(
        private readonly modalService: ModalService,
        private readonly authService: AuthService,
        private readonly listItemGQL: ListItemGQL,
        private readonly dateTimeService:DateTimeService,
        private readonly locationService:LocationService,
        private readonly messageService:MessageService,
    ) {
    }

    public listItem() {
        this.modalService.open({
            component: ListItemModalComponent,
            inputs: {
                item: this.item()
            }
        });
    }

    public quickListItem() {
        this.isLoading.set(true);
        this.listItemGQL.mutate(
            {
                input: {
                    itemId: this.item()?.id,
                    availability: this.dateTimeService.formatDateRange(null, null),
                    location: this.locationService.locationToLocationInput(this.item().location)
                }
            },
            {
                refetchQueries: [{
                    query: CompanyByIdDocument,
                    variables: { id: this.item()?.company!.id }
                }],
                awaitRefetchQueries: true
            }
        ).subscribe({
            next: () => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Inventaris aangeboden!'
                });
                this.isLoading.set(false);
            },
            error: (error) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Er is een fout opgetreden'
                });
                this.isLoading.set(false);
            },
        });
    }

    public renewListing() {
        this.modalService.open({
            component: ListItemModalComponent,
            inputs: {
                item: this.item(),
                mode: 'renew'
            }
        });
    }

    public cancelListing() {
        this.modalService.open({
            component: CancelListingModalComponent,
            inputs: {
                item: this.item()
            }
        });
    }
}
