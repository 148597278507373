<div class="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-md sm:border">
    <div class="px-4 py-4 sm:px-4 lg:grid lg:grid-cols-12 lg:gap-x-6 lg:p-4">
        <div class="sm:flex lg:col-span-7">
            <div
                class="aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-20 sm:w-20">
                <img
                    [image]="item().images[0]?.image"
                    [width]="200"
                    [height]="200"
                    class="h-full w-full object-cover object-center sm:h-full sm:w-full" [alt]="item().category!.name">
            </div>

            <div class="mt-6 sm:ml-4 sm:mt-0">
                <h3 class="text-lg font-medium text-gray-900">
                    <a href="#">{{ item().equipmentIdentifier }}</a>
                </h3>
                <p class="mt-1 text-sm font-medium text-gray-900">
                    {{ item().category!.name }}
                </p>
                <p class="mt-1 text-sm font-base text-gray-900">
                    {{ item().listing?.location?.properties?.formattedAddress ?? item().location.properties.formattedAddress }}
                </p>
            </div>
        </div>

        <div class="mt-6 lg:col-span-5 lg:mt-0 flex lg:justify-end items-center">
            @if (item().status?.isListedAndAvailable && item().status?.expiresToday && canManage()) {
                <span class="isolate inline-flex rounded-md shadow-sm mr-2">
                    <button
                        (click)="renewListing()"
                        type="button" class="relative -ml-px inline-flex items-center rounded-md bg-white px-4 py-3 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                        Aanbod verlengen
                    </button>
                </span>
            }
            @if (item().status?.isListedAndAvailable || item().status?.isListedAndAvailableInFuture) {
                <span class="isolate inline-flex rounded-md shadow-sm">
                    <button
                        (click)="cancelListing()"
                        type="button" class="relative inline-flex items-center rounded-md bg-white px-4 py-3 text-sm font-semibold text-red-700 ring-1 ring-inset ring-red-600 hover:bg-red-50 focus:z-10">
                        Annuleren
                    </button>
                </span>
            }
            @if (!item().status?.isListedAndAvailable && !item().status?.isListedAndAvailableInFuture && canManage()) {
                <div class="flex gap-2">
                    <button
                        (click)="quickListItem()"
                        [disabled]="isLoading()"
                        [pTooltip]="tooltipContent"
                        tooltipPosition="top"
                        type="button"
                        class="inline-flex items-center rounded-md bg-white px-4 py-3 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        @if (isLoading()) {
                            <svg class="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-900" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                        }
                        Snel Aanbieden
                    </button>
                    <ng-template #tooltipContent>
                          <span class="text-sm">Bied per direct aan met de huidige standplaats</span>
                    </ng-template>
                    <button
                        (click)="listItem()"
                        [disabled]="isLoading()"
                        type="button"
                        class="inline-flex items-center rounded-md bg-white px-4 py-3 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
                        Aanbieden
                    </button>
                </div>
            }
        </div>
    </div>

    <div class="border-t border-gray-200 bg-white rounded-b-md px-4 py-2 sm:px-4 lg:px-4 lg:py-4">
        <h4 class="sr-only">Status</h4>
        <div class="flex flex-row items-center" aria-hidden="true">
            <div class="flex-grow ">
                <p class="text-sm font-medium text-gray-900">
                    <app-item-status-badges [item]="item()"/>
                </p>
            </div>
        </div>
    </div>
</div>
