import { Injectable } from '@angular/core';
import { Location, LocationInput } from '../../../../graphql/generated';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    locationToLocationInput(location: Location): LocationInput {
        return {
            properties: {
                address: {
                    houseNumber: location.properties.address?.houseNumber || '',
                    street: location.properties.address?.street || '',
                    city: location.properties.address?.city || '',
                    country: location.properties.address?.country || '',
                    postcode: location.properties.address?.postcode || ''
                },
                formattedAddress: location.properties.formattedAddress || ''
            },
            geometry: {
                coordinates: location.geometry.coordinates
            }
        };
    }
}
